import { getSelectedAddOnInfo } from "@selectors/apps";
import { EventsMap } from "redux-beacon";
import {
  trackActivateCancelCta,
  trackActivateProceedCta,
  trackActivateSubscribeNowLink,
  trackAppsDisneyPageLoad,
  trackConfirmOrderModalDisplay,
  trackDisneyError,
  trackDownloadAppsCta,
  trackHowToCheckLink,
  trackMultiSmcModalDisplay,
  trackOtpEditMobileNumberLink,
  trackOtpResendLink,
  trackOtpVerifyCta,
  trackPurchaseConfirmCta,
  trackPurchaseSubscribeNowCta,
  trackRequestOtpModalDisplay,
  trackTermsConditionsLink,
  trackBackToHomeCta,
  trackDisneyTraffic,
  trackDisneyTrafficSessionExpired,
  trackWaitingRetry,
  trackRetrySuccess,
  trackDisneyErrorCta,
  trackPurchaseActivation,
  trackwhatsAppHelplineUpgradePack,
  trackPurchaseIneligible,
  trackUpgradeMyPack,
  trackMultiPrimarySMC,
  trackWhatsAppHelplineMultiSMC,
} from "./analyticActions";
import {
  AppsDisneyErrorEventDefinition,
  AppsDisneyEventDefinition,
  appsDisneyHotstar,
  AppsDisneyTrafficEventDefinition,
  AppsDisneyTrafficSessionExpiredEventDefinition,
  DisneyPageType,
  DownloadAppsCtaEvent,
  EventType,
  TermsConditionsLinkEvent,
} from "./analytics";
import { reason } from "./constants";

const disneyErrorEvent: AppsDisneyErrorEventDefinition = (action, state) => {
  const {
    ulm: { ulmToken, puid },
  } = state;
  if (action.payload) {
    const {
      api_endpoint: apiEndpoint,
      error_code: errorCode,
      error_status: errorStatus,
      account_id: accountId,
    } = action.payload;
    return {
      event: "api_error",
      ulm_token: ulmToken,
      api_endpoint: apiEndpoint,
      error_code: errorCode,
      error_status: errorStatus,
      account_id: accountId || puid,
    };
  }
};

const appsDisneyPageLoadEvent: AppsDisneyEventDefinition<string> = (action) => {
  if (action.payload)
    return {
      event: EventType.View,
      content_type: appsDisneyHotstar,
      content_title: action.payload,
    };
};

const termsConditionsLinkEvent: AppsDisneyEventDefinition<TermsConditionsLinkEvent> = (action) => {
  if (action.payload) {
    const { pageType, title, href } = action.payload as TermsConditionsLinkEvent;
    return {
      event: EventType.Click,
      content_type: appsDisneyHotstar,
      content_list_type: pageType,
      content_title: title,
      content_id: href,
    };
  }
};

const activateCancelCtaEvent: AppsDisneyEventDefinition = () => ({
  event: EventType.Click,
  content_type: appsDisneyHotstar,
  content_list_type: DisneyPageType.Activate,
  content_title: "Cancel",
});

const activateProceedCtaEvent: AppsDisneyEventDefinition = () => ({
  event: EventType.Click,
  content_type: appsDisneyHotstar,
  content_list_type: DisneyPageType.Activate,
  content_title: "Proceed",
});

const purchaseSubscribeNowCtaEvent: AppsDisneyEventDefinition = (action, state) => {
  const {
    disneyAddOnsConfig,
    purchaseSelection: { addOnId },
  } = state.appsUser;
  return {
    event: EventType.Click,
    content_type: appsDisneyHotstar,
    content_list_type: DisneyPageType.Purchase,
    content_title: "Subscribe Now",
    content_category: getSelectedAddOnInfo(disneyAddOnsConfig, addOnId)?.title ?? "",
  };
};

const multiSmcModalDisplayEvent: AppsDisneyEventDefinition = () => ({
  event: EventType.Popup,
  content_type: appsDisneyHotstar,
  content_title: "SMC Pack Selection",
});

const howToCheckLinkEvent: AppsDisneyEventDefinition = () => ({
  event: EventType.Click,
  content_type: appsDisneyHotstar,
  content_list_type: "SMC Pack Selection CTA",
  content_title: "How to check?",
});

const activateSubscribeNowLinkEvent: AppsDisneyEventDefinition = () => ({
  event: EventType.Click,
  content_type: appsDisneyHotstar,
  content_list_type: "Multi SMC",
  content_title: "Subscribe now",
});

const confirmOrderModalDisplayEvent: AppsDisneyEventDefinition = () => ({
  event: EventType.Popup,
  content_type: appsDisneyHotstar,
  content_title: "Confirm Order",
});

const purchaseConfirmCtaEvent: AppsDisneyEventDefinition = (action, state) => {
  const {
    disneyAddOnsConfig,
    purchaseSelection: { addOnId },
  } = state.appsUser;
  return {
    event: EventType.Click,
    content_type: appsDisneyHotstar,
    content_list_type: "Confirm Order",
    content_title: getSelectedAddOnInfo(disneyAddOnsConfig, addOnId)?.descriptionHtml ?? "",
    content_category: getSelectedAddOnInfo(disneyAddOnsConfig, addOnId)?.title ?? "",
  };
};

const requestOtpModalDisplayEvent: AppsDisneyEventDefinition = () => ({
  event: EventType.Popup,
  content_type: appsDisneyHotstar,
  content_title: "Request OTP",
});

const otpResendLinkEvent: AppsDisneyEventDefinition = () => ({
  event: EventType.Click,
  content_type: appsDisneyHotstar,
  content_list_type: "Request OTP",
  content_title: "Resend OTP",
});

const otpEditMobileNumberLinkEvent: AppsDisneyEventDefinition = () => ({
  event: EventType.Click,
  content_type: appsDisneyHotstar,
  content_list_type: "Request OTP",
  content_title: "Edit Mobile Number",
});

const otpVerifyCtaEvent: AppsDisneyEventDefinition = () => ({
  event: EventType.Click,
  content_type: appsDisneyHotstar,
  content_list_type: "Request OTP",
  content_title: "Verify",
});

const downloadAppsCtaEvent: AppsDisneyEventDefinition<DownloadAppsCtaEvent> = (action) => {
  if (action.payload) {
    const { pageType, name, href } = action.payload;
    return {
      event: EventType.Click,
      content_type: appsDisneyHotstar,
      content_list_type: pageType,
      content_title: name,
      content_id: href,
    };
  }
};

const backToHomeCtaEvent: AppsDisneyEventDefinition<DisneyPageType> = (action, state) => {
  if (action.payload) {
    const { marketingPageUrl } = state.appsUser.appsConfig;
    return {
      event: EventType.Click,
      content_type: appsDisneyHotstar,
      content_list_type: action.payload,
      content_title: "Back to home",
      content_id: marketingPageUrl,
    };
  }
};

const disneyTrafficEvent: AppsDisneyTrafficEventDefinition = (action, state) => {
  if (action.payload) {
    const { puid } = state.ulm;
    const { account_id: accountId, screen_name: screenName, reason, api_endpoint: apiEndpoint } = action.payload;
    return {
      event: EventType.TrafficAnalysis,
      account_id: accountId || puid,
      screen_name: screenName,
      reason,
      api_endpoint: apiEndpoint ?? "",
    };
  }
};

const disneyTrafficSessionExpiredEvent: AppsDisneyTrafficSessionExpiredEventDefinition = (action) => {
  if (action.payload)
    return {
      event: EventType.TrafficAnalysis,
      account_id: "",
      screen_name: action.payload,
      reason: reason.sessionExpired,
      api_endpoint: "",
    };
};

const waitingRetryEvent: AppsDisneyEventDefinition = () => ({
  event: EventType.Click,
  content_type: appsDisneyHotstar,
  content_list_type: DisneyPageType.WaitingRoom,
  content_title: "Retry",
});

const retrySuccessEvent: AppsDisneyEventDefinition<string> = (action, state) => {
  if (action.payload) {
    const {
      appsUser: { accountIds },
    } = state;
    return {
      event: EventType.TrafficAnalysis,
      api_endpoint: "",
      account_id: accountIds,
      reason: reason.retrySuccess,
      screen_name: action.payload,
    };
  }
};

const disneyErrorCtaEvent: AppsDisneyEventDefinition<string | undefined> = (action, state) => {
  const { errorCode, apiEndpoint } = state.appsError;
  return {
    event: EventType.Click,
    content_type: appsDisneyHotstar,
    content_title: action.payload ?? "NA",
    content_list_type: errorCode,
    content_category: apiEndpoint || "NA",
  };
};

const purchaseActivationEvent: AppsDisneyEventDefinition = () => {
  return {
    event: EventType.Click,
    content_type: appsDisneyHotstar,
    content_title: "Add on Disney+ Hotstar / Upgrade Pack",
    content_list_type: "Purchase Activation Options",
  };
};

const whatsAppHelplineUpgradePack: AppsDisneyEventDefinition = () => {
  return {
    event: EventType.Click,
    content_type: appsDisneyHotstar,
    content_list_type: "Purchase Activation Upgrade Pack",
    content_title: "Chat with us",
  };
};

const whatsAppHelplineMultiSMC: AppsDisneyEventDefinition = () => {
  return {
    event: EventType.Click,
    content_type: appsDisneyHotstar,
    content_list_type: "SMC Pack Selection CTA",
    content_title: "Reach out on Whatsapp",
  };
};

const purchaseIneligible: AppsDisneyEventDefinition = () => {
  return {
    event: EventType.View,
    content_type: appsDisneyHotstar,
    content_title: "Legacy Upgrade Pack",
  };
};

const upgradeMyPack: AppsDisneyEventDefinition = () => {
  return {
    event: EventType.Click,
    content_type: appsDisneyHotstar,
    content_title: "Upgrade Pack",
    content_list_type: "Legacy Upgrade Pack",
  };
};

const multiPrimarySMC: AppsDisneyEventDefinition = (action, state) => {
  if (action.payload) {
    const smc = state.appsUser.accounts.purchase.map((item) => item.smartcards);
    const name = smc
      .find((item) => item.find((item) => item.smartcardNumber === action.payload)?.name)
      ?.find((item) => item.name)?.name;
    return {
      event: EventType.Click,
      content_type: appsDisneyHotstar,
      content_title: name,
      content_list_type: "SMC Pack Selection",
    };
  }
};

const appsEventsMap: EventsMap = {
  [trackDisneyError.toString()]: disneyErrorEvent,
  [trackAppsDisneyPageLoad.toString()]: appsDisneyPageLoadEvent,
  [trackTermsConditionsLink.toString()]: termsConditionsLinkEvent,
  [trackPurchaseSubscribeNowCta.toString()]: purchaseSubscribeNowCtaEvent,
  [trackMultiSmcModalDisplay.toString()]: multiSmcModalDisplayEvent,
  [trackHowToCheckLink.toString()]: howToCheckLinkEvent,
  [trackActivateSubscribeNowLink.toString()]: activateSubscribeNowLinkEvent,
  [trackConfirmOrderModalDisplay.toString()]: confirmOrderModalDisplayEvent,
  [trackPurchaseConfirmCta.toString()]: purchaseConfirmCtaEvent,
  [trackActivateCancelCta.toString()]: activateCancelCtaEvent,
  [trackActivateProceedCta.toString()]: activateProceedCtaEvent,
  [trackRequestOtpModalDisplay.toString()]: requestOtpModalDisplayEvent,
  [trackOtpResendLink.toString()]: otpResendLinkEvent,
  [trackOtpEditMobileNumberLink.toString()]: otpEditMobileNumberLinkEvent,
  [trackOtpVerifyCta.toString()]: otpVerifyCtaEvent,
  [trackDownloadAppsCta.toString()]: downloadAppsCtaEvent,
  [trackBackToHomeCta.toString()]: backToHomeCtaEvent,
  [trackDisneyTraffic.toString()]: disneyTrafficEvent,
  [trackDisneyTrafficSessionExpired.toString()]: disneyTrafficSessionExpiredEvent,
  [trackWaitingRetry.toString()]: waitingRetryEvent,
  [trackRetrySuccess.toString()]: retrySuccessEvent,
  [trackDisneyErrorCta.toString()]: disneyErrorCtaEvent,
  [trackPurchaseActivation.toString()]: purchaseActivationEvent,
  [trackwhatsAppHelplineUpgradePack.toString()]: whatsAppHelplineUpgradePack,
  [trackPurchaseIneligible.toString()]: purchaseIneligible,
  [trackUpgradeMyPack.toString()]: upgradeMyPack,
  [trackMultiPrimarySMC.toString()]: multiPrimarySMC,
  [trackWhatsAppHelplineMultiSMC.toString()]: whatsAppHelplineMultiSMC,
};

export default appsEventsMap;
