import { FunctionComponent } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

export interface ContainerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  fullWidthMobile?: boolean;
}

const useStyles = makeStyles<Theme, ContainerProps>((theme) =>
  createStyles({
    container: {
      position: "relative",
      width: "100%",
      maxWidth: 784,
      margin: "0 auto",
      padding: (props) => (props.fullWidthMobile ? 0 : "0 1rem"),
      [theme.breakpoints.up("desktop")]: {
        // NOTE: need to have (props) for this rule to sequence the css after the mobile rules
        padding: (props) => "0 1rem",
      },
    },
  })
);

const Container: FunctionComponent<ContainerProps> = (props) => {
  const { children, fullWidthMobile, className, ...divProps } = props;
  const styles = useStyles({ fullWidthMobile });
  return (
    <div className={clsx(styles.container, className)} {...divProps}>
      {children}
    </div>
  );
};

Container.displayName = "Container";

export default Container;
