import { ReportHandler } from "web-vitals";

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[];
  }
}
window.dataLayer = window.dataLayer || [];

function push(event: Record<string, unknown>) {
  try {
    window?.dataLayer?.push(event);
  } catch (e) {
    console.error(e ? e : "Error: GTM container not loaded");
  }
}

export const vitalsMetricHandler: ReportHandler = (metric) => {
  const { name, delta, id } = metric;
  push({
    event: "web-vitals",
    event_category: "Web Vitals",
    event_action: name,
    event_value: Math.round(name === "CLS" ? delta * 1000 : delta),
    event_label: id,
  });
};
