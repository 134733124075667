import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

import { AppState } from "@store";
import { LogoutModalLanguageConfig } from "@ducks/types";
import {
  loginUrlUpdated,
  ulmStatusUpdated,
  trackLogoutModalActions,
  openLogoutModal,
  closeLogoutModal,
} from "@ducks/ulm";
import { ISpeed } from "@ducks/config/types";

import { Header as ACMHeader, Nullable, UlmInit, UlmProfile, logout, HeaderConfig } from "acm-components";
import Button from "@components/Button";
import SmallModal from "@components/modals/SmallModal";
import { urlParameterKeys } from "@lib/urlQueryMap";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginBottom: "1rem",
      fontWeight: "bold",
    },
    description: {
      marginBottom: "1.5rem",
    },
    yesButton: {
      marginBottom: "1rem",
    },
  })
);

interface HeaderProps {
  config: HeaderConfig;
}

const Header: React.FC<HeaderProps> = ({ config }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const l = useSelector<AppState, LogoutModalLanguageConfig>((state) => state.language.logoutModal);
  const pageUrl = useSelector<AppState, string>((state) => state.shop.tier.pageUrl);
  const selectedSpeed = useSelector<AppState, ISpeed | null>((state) => state.shop.selectedSpeed);
  const hasLogoutModal = useSelector<AppState, boolean>((state) => state.ulm.hasLogoutModal);
  const isLogoutModalOpened = useSelector<AppState, boolean>((state) => state.ulm.isLogoutModalOpened);
  const redirectToMarketingPage = useSelector<AppState, boolean>((state) => state.ulm.redirectToDisneyMarketingPage);
  const disneyMarketingPageUrl = useSelector<AppState, string>((state) => state.appsUser.appsConfig.marketingPageUrl);

  const handleInit = useCallback(
    ({ ulmUrl }: UlmInit) => {
      dispatch(loginUrlUpdated(ulmUrl));
    },
    [dispatch]
  );

  const handleAuth = useCallback(
    (params: Nullable<UlmProfile>) => {
      dispatch(ulmStatusUpdated(params));
    },
    [dispatch]
  );

  const handleModalClose = useCallback(() => {
    dispatch(trackLogoutModalActions("Cancel"));
    dispatch(closeLogoutModal());
  }, [dispatch]);

  const handleLogout = useCallback(() => {
    logout();
    dispatch(closeLogoutModal());
    dispatch(ulmStatusUpdated(null));
    if (hasLogoutModal) {
      const params = new URLSearchParams();
      if (selectedSpeed) params.set(urlParameterKeys.speed, selectedSpeed.urlParams);
      const url = new URL(pageUrl, window.location.origin);
      url.search = params.toString();

      dispatch(trackLogoutModalActions("Logout"));
      window.location.href = url.href;
    } else if (redirectToMarketingPage) {
      window.location.href = disneyMarketingPageUrl;
    }
  }, [dispatch, hasLogoutModal, pageUrl, selectedSpeed, redirectToMarketingPage, disneyMarketingPageUrl]);

  const handleModalOpen = useCallback(() => {
    if (hasLogoutModal) dispatch(openLogoutModal());
    else handleLogout();
  }, [dispatch, handleLogout, hasLogoutModal]);

  return (
    <>
      <ACMHeader
        config={config}
        shouldOverrideLogout
        hideLanguageSwitcher
        onInit={handleInit}
        onAuth={handleAuth}
        onLogout={handleModalOpen}
      />
      {hasLogoutModal && (
        <SmallModal open={isLogoutModalOpened} onClose={handleModalClose} hasCloseIcon>
          <h3 className={styles.title}>{l.title}</h3>
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: l.descriptionHtml }} />
          <Button className={styles.yesButton} onClick={handleLogout} magenta>
            {l.continueLogout}
          </Button>
          <Button onClick={handleModalClose}>{l.cancelLogout}</Button>
        </SmallModal>
      )}
    </>
  );
};

Header.displayName = "Header";

export default Header;
