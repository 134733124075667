import { Middleware } from "redux";
import { AppState } from "@store";
import { initUlmHandlerTriggered, initUlmUuid, ulmUuidUpdated, ulmHandlerTriggered } from "@ducks/ulm";
import { ulmUuidCookie, ulmHandlerTriggeredCookie } from "@ducks/ulm/constants";
import { getDomain } from "@lib/common";
import { getCookie, removeCookie } from "acm-components";

const ulmMiddleware: Middleware<unknown, AppState> = () => (next) => (action) => {
  switch (action.type) {
    case initUlmUuid.toString():
      const ulmUuid = getCookie(ulmUuidCookie);
      next({ type: ulmUuidUpdated.toString(), payload: ulmUuid });
      break;
    case initUlmHandlerTriggered.toString():
      const ulmHandlerCookie = getCookie(ulmHandlerTriggeredCookie);
      const domain = getDomain();
      removeCookie(ulmHandlerTriggeredCookie, domain);
      next({ type: ulmHandlerTriggered.toString(), payload: !!ulmHandlerCookie });
      break;
    default:
      next(action);
      break;
  }
};

export default ulmMiddleware;
