export default function psl(location: string): string | null {
  const domainlist = ["pink.cat", "astro.com.my"];
  const regex = new RegExp(`(${domainlist.join("|")})`, "g");
  const match = location.match(regex);
  if (!match) return match;
  else return match[0];
}

export function isLocalhost() {
  return Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
}

export function interpolate(str: string, params: { [key: string]: string }) {
  for (const [key, value] of Object.entries(params)) {
    const regex = RegExp(`{${key}}`, "g");
    let matches;
    while ((matches = regex.exec(str)) !== null) str = str.replace(matches[0], value);
  }
  return str;
}

export function getEncodedUrl(url: string) {
  try {
    return new URL(url).href;
  } catch (e) {
    return url;
  }
}

export function dedupe<T>(array: Array<T>) {
  return array.filter((item, index) => array.indexOf(item) === index);
}

export function mapKeyToValue<T = string>(key: string, dictionary: Record<string, T>, keyCaseSensitive = false) {
  const attribute = key.toLowerCase();
  if (attribute) if (dictionary[attribute]) return dictionary[attribute];
  return null;
}

export function getValuesFromMap<T = string>(keys: string[], dictionary: Record<string, T>) {
  return keys.reduce((acc, key) => {
    const value = mapKeyToValue(key, dictionary);
    if (value) acc.push(value);
    return acc;
  }, [] as T[]);
}

export function roundToDecimal(value: number, decimalPlaces = 2) {
  const offset = Math.pow(10, decimalPlaces);
  return Math.round(value * offset) / offset;
}

export function capitalizeString(s: string): string {
  return s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase();
}

export function capitalizeSentence(s: string): string {
  return s.split(" ").map(capitalizeString).join(" ");
}

export function addSpaceAfterPunctuation(sentence: string): string {
  return sentence.replace(/([,.])(?=\S)/g, "$1 ");
}

export function getDomain(): string {
  return `.${psl(window.location.hostname) || window.location.hostname}`;
}
