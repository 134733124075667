import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "intersection-observer";

import App from "@app";
import "./index.css";
import store from "@store";
import { vitalsMetricHandler } from "@lib/gtm";
import reportWebVitals from "./reportWebVitals";
const container = document.getElementById("YWNtLXNob3A");

if (container) {
  ReactDOM.createRoot(container).render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}

reportWebVitals(vitalsMetricHandler);
